(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/player-exclusions.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/player-exclusions.js');

'use strict';

const {
  get: getExclusions,
  set: setExclusions,
  reset: resetExclusions
} = svs.accountservices.player_exclusions.activeExlucions;
let gamingPauseDialog;
const showPauseDialog = _ref => {
  let {
    title,
    text,
    actions,
    icon = 'self-test',
    message
  } = _ref;
  message = message || [{
    align: svs.ui.dialog.message.align.CENTER,
    text,
    type: svs.ui.dialog.message.TEXT
  }];
  gamingPauseDialog = new svs.ui.dialog.Confirm({
    actions,
    allowDimmerClick: svs.core.detect.formfactor.mobile(),
    area: svs.ui.dialog.area.POPUP,
    branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
    icon,
    message,
    title,
    type: svs.ui.dialog.type.DEFAULT
  });
  svs.ui.dialog.api.add(gamingPauseDialog);
  return gamingPauseDialog;
};
const newBtnActions = cb => [{
  title: 'Stäng',
  callback: () => {
    cb === null || cb === void 0 || cb();
    gamingPauseDialog.close();
  }
}, {
  title: 'Kontakta oss',
  callback: () => {
    svs.utils.url.navigate(svs.core.urlMapping.get('gameCareContact'));
  }
}];
const showTTDialog = cb => {
  showPauseDialog({
    actions: newBtnActions(cb),
    text: svs.accountservices.player_exclusions.template_literals.tt_restriction(),
    title: 'Just nu kan du inte spela eller sätta in pengar',
    icon: 'help-2'
  });
};
const checkHasTTRestrictionAndShowDialog = async cb => new Promise(resolve => {
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    getExclusions(activeExclusions => {
      if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasTTRestriction) {
        showTTDialog(cb);
        resolve(true);
      } else {
        resolve(false);
      }
    }, true);
  } else {
    resolve(false);
  }
});
const showGCSDialog = (gcsData, cb) => {
  showPauseDialog({
    actions: [{
      title: 'Logga ut',
      callback: () => {
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('logout'), "?returnUrl=/"));
      }
    }, {
      title: 'Okej',
      callback: () => {
        cb === null || cb === void 0 || cb();
        gamingPauseDialog.close();
      }
    }],
    text: svs.accountservices.player_exclusions.template_literals.gcs_restriction(gcsData),
    title: 'Just nu kan du inte spela eller sätta in pengar',
    icon: 'help-2'
  });
};
const checkHasRestrictionAndShowDialog = async cb => new Promise(resolve => {
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    let hasRestriction = false;
    getExclusions(activeExclusions => {
      if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasTTRestriction) {
        showTTDialog(cb);
        hasRestriction = true;
      } else if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasGCSRestriction) {
        showGCSDialog(activeExclusions.gcsData, cb);
        hasRestriction = true;
      } else if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasAMDRestriction) {
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('transactionsHome'), "/begransning"));
        hasRestriction = true;
      } else if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasTMDTLRestriction) {
        svs.accountservices.deposit_restriction.DepositRestrictionDialogs.showNotAllowToDepositDialog();
        hasRestriction = true;
      }
      if (hasRestriction) {
        resetExclusions();
        resolve(true);
      } else {
        resolve(false);
      }
    }, true);
  } else {
    resolve(false);
  }
});
const checkHasGCSRestrictionAndShowDialog = async cb => new Promise(resolve => {
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    getExclusions(activeExclusions => {
      if (activeExclusions !== null && activeExclusions !== void 0 && activeExclusions.hasGCSRestriction) {
        showGCSDialog(activeExclusions.gcsData, cb);
        resetExclusions();
        resolve(true);
      } else {
        resolve(false);
      }
    }, true);
  } else {
    resolve(false);
  }
});

const canPlayOnVertical = async (vertical, callback) => {
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    const exclusionData = await svs.accountservices.player_exclusions.ExclusionsData.getExclusionData(vertical);
    let actions;
    if (!exclusionData || exclusionData.canPlay) {
      return true;
    }
    const {
      title,
      icon,
      text
    } = exclusionData;
    if (exclusionData.actions) {
      actions = exclusionData.actions.map(action => {
        const originalCallback = action.callback;
        if (originalCallback) {
          const newCallBack = () => {
            callback === null || callback === void 0 || callback();
            originalCallback();
          };
          return {
            title: action.title,
            callback: newCallBack
          };
        }
        return action;
      });
    }
    actions = actions || [{
      title: 'Okej',
      callback: () => {
        var _gamingPauseDialog;
        callback === null || callback === void 0 || callback();
        (_gamingPauseDialog = gamingPauseDialog) === null || _gamingPauseDialog === void 0 || _gamingPauseDialog.close();
      }
    }];
    showPauseDialog({
      title,
      actions,
      icon,
      text
    });
    return false;
  }
  return true;
};
svs.accountservices.player_exclusions.canPlayOnVertical = canPlayOnVertical;
svs.accountservices.player_exclusions.getActiveExlusions = getExclusions;
svs.accountservices.player_exclusions.saveExclusions = setExclusions;
svs.accountservices.player_exclusions.clearCache = resetExclusions;
svs.accountservices.player_exclusions.checkHasTTRestrictionAndShowDialog = checkHasTTRestrictionAndShowDialog;
svs.accountservices.player_exclusions.checkHasGCSRestrictionAndShowDialog = checkHasGCSRestrictionAndShowDialog;
svs.accountservices.player_exclusions.showTTDialog = showTTDialog;
svs.accountservices.player_exclusions.showGCSDialog = showGCSDialog;
svs.accountservices.player_exclusions.checkHasRestrictionAndShowDialog = checkHasRestrictionAndShowDialog;

 })(window);